import {Injectable} from '@angular/core';
import {Query} from 'apollo-angular';
import gql from 'graphql-tag';
import {Relay} from '../../../../../../common/interfaces/prisma.binding';

export interface Response {
  ownRelays: Relay[];
}

@Injectable({
  providedIn: 'root',
})
export class OwnRelaysQuery extends Query<Response> {
  document = gql`
      query getOwnRelays {
          getOwnRelays {
              id
              description
          }
      }
  `;
}
