import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {SwitchComponent} from './modules/switch-test/components/switch/switch.component';

const routes: Routes = [
  {path: 'switch', component: SwitchComponent},
  {path: 'home', component: SwitchComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
