import {Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material';
import {AuthConfig, JwksValidationHandler, OAuthService} from 'angular-oauth2-oidc';
import {filter} from 'rxjs/operators';
import {environment} from '../../common/angular/environments/environment';


@Injectable()
export class AppInitService {
  authConfig: AuthConfig = environment.authConfig;

  constructor(private oauthService: OAuthService,
              public snackBar: MatSnackBar) {
  }

  Init() {
    return new Promise<void>((resolve, reject) => {

      this._configureWithNewConfigApi().then((data) => {
        if (!this.oauthService.hasValidIdToken() || !this.oauthService.hasValidAccessToken()) {
          this.oauthService.logOut();
          // redirect to Keycloak Login Page
          this.oauthService.initImplicitFlow('some-state');
        }
        // if we already signed in with keycloak put everything in store
        let keycloakId = '';
        let authToken = '';
        const claim = this.oauthService.getIdentityClaims();
        if (claim !== null) {
          keycloakId = claim['sub'];
          authToken = this.oauthService.getAccessToken();
        }
        if (keycloakId !== '') {
          console.log(keycloakId);
          resolve();
        }
      });
    });
  }


// This api will come in the next version
  private _configureWithNewConfigApi(): Promise<any> {
    this.authConfig.silentRefreshRedirectUri = 'localhost:4201/silent-refresh.html';
    this.oauthService.configure(this.authConfig);

    // this.oauthService.setStorage(localStorage);
    this.oauthService.tokenValidationHandler = new JwksValidationHandler();
    // Optional
    // this.oauthService.setupAutomaticSilentRefresh();

    this.oauthService.events.subscribe(e => {
      // tslint:disable-next-line:no-console
      console.debug('oauth/oidc event', e);
    });


    this.oauthService.events
      .pipe(filter(e => e.type === 'session_terminated'))
      .subscribe(e => {
        // tslint:disable-next-line:no-console
        console.debug('Your session has been terminated!');
      });
    // This happens after login
    this.oauthService.events
      .pipe(filter(e => e.type === 'token_received'))
      .subscribe(e => {
        console.log('Token recieved');
        // this.oauthService.loadUserProfile().then(_ => {
        //   // put everything into store
        //   const claim = this.oauthService.getIdentityClaims();
        //   this.store
        //     .dispatch(new SignIn(claim['sub'], this.oauthService.getAccessToken()))
        //     .subscribe(success => {}, error => {});
        // });
      });
    this.oauthService.events
      .pipe(filter(e => e.type === 'token_expires'))
      .subscribe(e => {
        console.log('Silent refresh');
        // this
        //   .oauthService
        //   .silentRefresh()
        //   .then(info => console.debug('refresh ok', info))
        //   .catch(err => console.error('refresh error', err));
        // this.oauthService.loadUserProfile().then(_ => {
        //   // put everything into store
        //   const claim = this.oauthService.getIdentityClaims();
        //   this.store
        //     .dispatch(new SignIn(claim['sub'], this.oauthService.getAccessToken()))
        //     .subscribe(success => {}, error => {});
        // });
      });

    return this.oauthService.loadDiscoveryDocumentAndTryLogin();
  }
}
