import {LayoutModule} from '@angular/cdk/layout';
import {registerLocaleData} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import {APP_INITIALIZER, LOCALE_ID, NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {ErrorStateMatcher, ShowOnDirtyErrorStateMatcher} from '@angular/material/core';
import {MatIconModule} from '@angular/material/icon';
import {MatListModule} from '@angular/material/list';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatToolbarModule} from '@angular/material/toolbar';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';


import {AuthConfig, JwksValidationHandler, OAuthModule, ValidationHandler} from 'angular-oauth2-oidc';
import {LoggerModule, NgxLoggerLevel} from 'ngx-logger';
import {environment} from '../../../common/angular/environments/environment';
import {AppInitService} from '../app-init.service';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {GraphQLModule} from './graphql.module';
import {MainComponent} from './modules/main/main.component';
import {SwitchTestModule} from './modules/switch-test/switch-test.module';

registerLocaleData(localeDe, 'de');

export function initializeApp(appInitService: AppInitService) {
  return (): Promise<any> => {
    return appInitService.Init();
  };
}


@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
  ],

  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    GraphQLModule,
    HttpClientModule,
    LayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatIconModule,
    MatListModule,
    SwitchTestModule,
    LoggerModule.forRoot({level: NgxLoggerLevel.TRACE}),
    OAuthModule.forRoot({
      resourceServer: {
        sendAccessToken: true,
      },
    }),
  ],
  providers: [
    {provide: AuthConfig, useValue: environment.authConfig},
    AppInitService,
    {provide: APP_INITIALIZER, useFactory: initializeApp, deps: [AppInitService], multi: true},
    {provide: LOCALE_ID, useValue: 'de'}, {
      provide: ErrorStateMatcher,
      useClass: ShowOnDirtyErrorStateMatcher,
    },
    {provide: ValidationHandler, useClass: JwksValidationHandler},
  ],
  bootstrap: [AppComponent],
})

export class AppModule {

}
