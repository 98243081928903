import {HttpClient} from '@angular/common/http';
import {Component, OnInit} from '@angular/core';
import {Apollo} from 'apollo-angular';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {environment} from '../../../../../../../common/angular/environments/environment';
import {Relay} from '../../../../../../../common/interfaces/prisma.binding';
import {RelayPostMessageInterface} from '../../../../../../../common/interfaces/relay-post-message.interface';
import {OwnRelaysQuery} from '../../../shared/queries/own-relays.query';
import {RelayChangedSubscription} from './relay-changed.subscription';

export interface IRelayRelaystate {
  state: boolean;
  disabled: boolean;
}

export interface IRelayResponse {
  relay: Relay;
  relayState: IRelayRelaystate;
}

@Component({
  selector: 'tt-switch',
  templateUrl: './switch.component.html',
  styleUrls: ['./switch.component.scss'],
})
export class SwitchComponent implements OnInit {
  numbers = Array.from(Array(16), (_, x) => {
    return {number: x, value: true};
  });

  ownRelays$: Observable<any>;
  relays = new Map<string, IRelayRelaystate>();

  constructor(private http: HttpClient,
              private apollo: Apollo,
              private relayChangedSubscription: RelayChangedSubscription,
              private readonly ownRelaysQuery: OwnRelaysQuery) {
  }

  ngOnInit() {
    this.relayChangedSubscription.subscribe().pipe(map(data => data.data.onRelayChange))
      .subscribe(relayStatesString => {
        const relayStates: IRelayResponse[] = JSON.parse(relayStatesString);
        console.log(relayStates);
        relayStates.forEach((currentRelayState: IRelayResponse) => {
          if (this.relays.has(currentRelayState.relay.id)) {
            this.relays.set(currentRelayState.relay.id, currentRelayState.relayState);
          }
        });
      });
    console.log('Ralle', this.relays);
    this.ownRelays$ = this.ownRelaysQuery.watch({}, {
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(map((result) => {
        result.data['getOwnRelays'].forEach(relay => this.relays.set(relay.id, {state: false, disabled: false}));
        return result.data['getOwnRelays'];
      }));
    this.http.get(`${environment.imageBackend}relay`)
      .subscribe(data => console.log(data));
  }

  toggleAll() {
    this.relays.forEach((relay, key) => this.relays.set(key, {
      state: !this.relays.get(key).state,
      disabled: !this.relays.get(key).disabled,
    }));
    this.onSwitchChange(Array.from(this.relays.keys()));
  }

  testToggle() {
    this.http.get(`${environment.imageBackend}relay`)
      .subscribe(data => console.log(data));

  }

  onSwitchChange(relay: string | string[]) {
    if (!Array.isArray(relay)) {
      relay = [relay];
    }
    let message: RelayPostMessageInterface = {
      relayIds: relay,
      relayStates: relay.map(relayId => {
        return {id: relayId, relayState: this.relays.get(relayId)};
      }),
    };

    this.http.post(`${environment.imageBackend}relay`, message)
      .subscribe(data => console.log(data));
  }
}
