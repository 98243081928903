import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {SwitchComponent} from './components/switch/switch.component';

@NgModule({
  declarations: [SwitchComponent],
  imports: [
    CommonModule,
    MatSlideToggleModule,
    FormsModule,
    MatButtonModule,
  ],
})
export class SwitchTestModule {
}
